<template>
  <div>
    <base-header class="header py-5 d-flex align-items-center mb-3"
                 style="height: 50vh; min-height: 300px; background-image: url(img/home-fondo.jpg); background-size: cover; background-position: center top; ">
      <!-- Mask -->
      <!--  <div class="mask bg-gradient-gray-dark opacity-4" />
      <!-- <div class="mask bg-gradient-success opacity-4"></div>
      -->
      <!-- Header container -->
      <div class=" align-items-center ">
        <div class="row">
          <div class="col-lg-7 col-md-10">

            <h1 class="display-2 text-white mt-5">Hola {{Nombre}}</h1>
            <p class="text-white mt-0 mb-5">App de control de visitantes</p>
            <!-- <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>

    </base-header>

    <div>
      <div class="row px-4 mb-0">
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain to="/reuniones" :numero="0" texto="Reuniones" icono="fas fa-calendar" />
        </div>
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain to="/accesos" :numero="0" texto="Accesos" icono="fas fa-door-open" />
        </div>
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain to="/usuarias" :numero="0" texto="Usuarios" icono="fas fa-users" />
        </div>
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain :to="{}" @click="MostrarRegistro=true" :numero="0" texto="Escanar QR" icono="fas fa-qrcode" />
        </div>
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain to="/ubicaciones" :numero="0" texto="Ubicaciones" icono="fas fa-building" />
        </div>
        <div class="col-lg-3 col-6 px-1 px-md-2 pb-2 pb-md-3">
          <BotonMain to="/dispositivos" :numero="0" texto="Dispositivos" icono="fas fa-fingerprint" />
        </div>
      </div>
    </div>
    <!--
    <div class="mask ">
      <div class="fixed-bottom text-center  mb-2"  style="width:100%">
          <div class="border border-primary rounded-circle" style="width: 64px; height: 64px; display: inline-block" width="64px">

          </div>


      </div>

    </div>-->
    <RegistroModal v-if="MostrarRegistro" :Mostrar.sync="MostrarRegistro" v-model="datos" :autoClose="false"></RegistroModal>
  </div>

</template>

<script>
  import Usuarios from '@/api-base/usuarios.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import BaseHeader from 'src/components/BaseHeader';
  import { BaseSwitch } from 'src/components';
  import BotonMain from '@/Vistas/Main/BotonMain.comp';
  const RegistroModal = () => import( /* webpackChunkName: "usuarios" */ '@/Vistas/Reuniones/Registro.modal');

  export default {
    name: 'Main-Empleado',
    components: {
      BaseHeader,
      BaseSwitch,
      BotonMain,
      RegistroModal,
    },
    data() {
      return {
        Saludo: 'Buen día',
        //   Nombre: 'Usuario',
        datos: {},
        Obscuro: true,
        LasNotificaciones: null,
        MostrarRegistro: false,
        Perfil: '',
      }
    },
    computed: {
      Nombre() {
        var N = Usuarios.PerfilLocal ? Usuarios.PerfilLocal.usuario.nombre : "";
        return N;
      },
      HayNotificacionClass() {
        if (this.LasNotificaciones && this.LasNotificaciones.length > 0)
          return " notification";
        return "";
      },
    },
    mounted() {
      var n = null;
      if (this.$route.query.n != null || this.$route.params.n != null) {
        n = this.$route.query.n != null ? this.$route.query.n : this.$route.params.n;
        console.debug(n);
      }
      if (this.$route.query.notificacionID != null || this.$route.params.notificacionID != null) {
        n = this.$route.query.notificacionID != null ? this.$route.query.notificacionID : this.$route.params.notificacionID;
        console.debug(n);
      }

      // if (notificacionID)
      //
      if (localStorage.Obscuro != null) {
        this.Obscuro = localStorage.Obscuro == "true";
        console.debug(localStorage.Obscuro);
      }
      this.toggleMode(this.Obscuro);

      var SU = SuscripcionesUsuarios.getSuscripcionUsuario();
      this.Perfil = SU.perfil;
      if (!SU.esAdministrador) {
        switch (SU.perfil) {
          case "UsuarioRegular":
            //this.$router.clear
            this.$router.replace("/reuniones");
            break;
          case "Vigilante":
            //this.$router.clear
            this.$router.replace("/main/homeVigilante");
            break;
        }
      }
    },
    methods: {

      toggleMode(type) {
        if (localStorage.Obscuro != type) {
          localStorage.Obscuro = type;
          this.$appInsights.trackEvent({ name: this.$options.name }, { Obscuro: type });
        }
        let docClasses = document.body.classList;
        if (type == true) {
          docClasses.remove('white-content');
          docClasses.add('maz-is-dark');

        } else {
          docClasses.add('white-content');
          docClasses.remove('maz-is-dark');
        }
      },

    }
  }
</script>

<style scoped>
</style>
