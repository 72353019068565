<template>
  <router-link :to="to" @click.native="$emit('click')">
    <div class="card mb-0 d-table" style="min-height: 8rem; height:100%">

      <div class="card-body text-center d-table-cell align-middle ">
        <i :class="icono" class="fa-3x"></i>
        <h1 v-if="numero>0" class="mb-0 mx-0 mt-2">{{numero}}</h1>
        <p class="text-muted ">{{texto}}</p>
      </div>

    </div>
  </router-link>
</template>

<script>


  export default {
    name: 'BotonMain-comp',
    components: {
    },
    props: {

      to: { type: [Object, String], default: null },
      icono: { type: String, default: "fas fa-file-invoice-dollar" },
      numero: { type: Number, default: 0 },
      texto: { type: String, default: "Prueba"},

    },
    data() {
      return {

      }
    },
    watch: {

    },
    mounted() {

    },
    methods: {


    }
  }</script>

<style scoped>
</style>
